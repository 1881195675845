import {axiosClient} from '../api/httpClient';

export const storeInboxMessage = async (postData) => {
	try {
		const data = await axiosClient.post('/api/inbox/create', postData);
		return {
            status: true,
			data: data?.message
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getInboxMessages = async (postData) => {
	try {
		const data = await axiosClient.get(`/api/inbox/list/${postData.status}`, { params: postData });
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getChatByMessageId = async (calledBy, postData) => {
	let id;
	if (calledBy === 'scrollCalled') {
		id = postData.currentSelectedMessage;
	} else {
		id = postData.id;
	}
	try {
		const data = await axiosClient.get(`/api/inbox/chat/${id}`, { params: postData });
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const deleteComment = async (postData) => {
	try {
		const data = await axiosClient.post('/api/inbox/chat/deleteChat', postData);
		return {
            status: true,
			message: data?.data?.message,
			count: data?.data?.count
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const editChatComment = async (postData) => {
	try {
		const data = await axiosClient.put('/api/inbox/chat/editChat', postData);
		return {
            status: true,
			message: data?.data?.message
		}
	} catch (error) {
		return {
            status: false,
			error: "Unable to edit comment, try again later"
		}
	}
};

export const storeChatMessage = async (postData) => {
	try {
		const data = await axiosClient.post('/api/inbox/chat/send', postData);
		return {
            status: true,
			message: data?.data?.message,
			success: data?.data?.success,
			id: data?.data?.id,
			count: data?.data?.count,
		}
	} catch (error) {
		return {
            status: false,
			error: "Unable to post comment, try again later"
		}
	}
};

export const handleInboxMessageStatus = async (postData) => {
	try {
		const data = await axiosClient.put(`/api/inbox/handleStatus`, postData);
		return {
            status: true,
			message: data?.data?.message
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const seenMessages = async (postData) => {
	try {
		console.log("postData", postData)
		await axiosClient.post('/api/inbox/seenMessages', postData);
		return {
            status: true,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const seenLatestMessage = async (id, seen) => {
	try {
		await axiosClient.post('/api/inbox/seenLatestMessage', {id, seen});
		return {
            status: true,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};
