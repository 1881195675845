import React from 'react';
import { Modal, Input } from 'antd';
import styles from './AddMessageModal.module.scss';
import { SET_ADD_TITLE, SET_INBOX_ADD_MESSAGE } from '../../../actions/types';
import { useDispatch, useSelector } from 'react-redux';

const AddMessageModal = ({ title, isModalOpen, handleOk, handleCancel, clearFields, }) => {
    const { addTitle, inboxAddMessage } = useSelector((state) => state.inboxSeenMessages);
    const dispatch = useDispatch();
    
    return (
        <Modal title={title}
            open={isModalOpen.status === 'Re-open' ? isModalOpen.open : isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            afterClose={() => clearFields()}
            okButtonProps={{ disabled: (addTitle && inboxAddMessage) ? false : true }}>
            <Input className={styles.addTitleField} disabled={isModalOpen.status === 'Re-open' ? true : false} value={addTitle} onChange={e => dispatch({ type: SET_ADD_TITLE, payload: e.target.value })} placeholder="Add Title" />
            <Input className={styles.addMessageField} value={inboxAddMessage} onChange={e => dispatch({ type: SET_INBOX_ADD_MESSAGE, payload: e.target.value })} placeholder="Add Message" />
        </Modal>
    )
}

export default AddMessageModal;
