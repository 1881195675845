import {axiosClient} from '../api/httpClient';

function countUnread(notifications, myId) {
    return notifications.reduce((unread, notification) => unread + isUnread(notification, myId), 0);
}

function isUnread(notification, myId) {
    return !notification.seen && notification.recipient_client_id === myId;
}

export const markAsRead = async (notifications, myId) => {
    if (countUnread(notifications, myId)) {
        await axiosClient.post("/api/notifications/read")
            .then(() => { })
            .catch(err => {
                console.error(err);
            });
    }
};

export const getNotifications = async (obj) => {
	try {
		const data = await axiosClient.get("/api/notificationsV2", {
            params: obj
        });
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const markAsReadNotifications = async (resultIds) => {
    try {
		const data = await axiosClient.post("/api/notifications/read",{resultIds});
		return {
			status: data?.status
		}
	} catch (error) {
		return {
			error: "Some Error Occured"
		}
	}
};

export const deleteOldNotifications = async () => {
	try {
		const data = await axiosClient.delete('/api/delete-notifications/3');
		return {
            status: true,
			message: data?.data?.message
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getNotificationReadHistory = async (uniqId) => {
    try {
		const data = await axiosClient.get(`/api/notifications/read-history/${uniqId}`);
		return {
			status: data?.status,
			data: data?.data
		}
	} catch (error) {
		return {
			error: "Some Error Occured"
		}
	}
};
