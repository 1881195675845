import React, { useEffect } from 'react';
import { Button, Col, Icon, Row, Textarea } from "react-materialize";
import { Empty, Spin } from 'antd';
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { CloseOutlined } from "@ant-design/icons";
import styles from './ChatSectionStyle.module.scss';
import { debounce } from 'lodash';

export default (props) => {
    
    let { state, teamState, chatView, loopLoadComments, mapComments, isLoading, replyState,
        setReplyState, INITIAL_REPLY_STATE, comment, setComment, video, keyPressHandler, editComment,
        send, disableBtn, hasMore = true, chatSectionType,section, allowNewChat = true } = props;
    useEffect(() => {
        let targetDiv = document.querySelector(".chat-cover");
        targetDiv.scrollTop = targetDiv.scrollHeight;
    }, [])

    return (
        <Row className={`comment_area ${styles.chatBody}`}>
            <div className={`chat-cover ${styles.chatCover}`}>
                {
                    (chatView.value === "team" ? teamState.chats : state.comments) && (chatView.value === "team" ? teamState.chats : state.comments).length !== 0 &&
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={hasMore}
                        isReverse={chatSectionType === "inboxChat" ? true : false}
                        style={{ display: "flex", flexDirection: chatSectionType === "inboxChat" ? "column-reverse" : "column" }}
                        useWindow={false}
                        // loadMore={() => loopLoadComments()}
                        loadMore={debounce(() => loopLoadComments(), 800)}
                        initialLoad={false}
                    >
                        {
                            Object.keys(chatView.value === "team" ? teamState.chats : state.comments).length > 0 ? mapComments(
                                    chatView.value === "team" ? teamState.chats : state.comments, chatView.value === "team" ? teamState.chatsArray : state.commentsArray
                                ) :
                                <Spin className={styles.loader} size="large" spinning={isLoading} />
                        }
                        {
                            chatView.value === "team" ? Object.keys(teamState.chats).length === 0 && <Empty /> : Object.keys(state.comments).length === 0 && <Empty />
                        }
                        
                    </InfiniteScroll>
                }

                {/* {
                noComments && ( chatView.value === "team" ? teamState?.chats : state?.comments)?.length === 0 &&
                <Col className="no-comment-label" >
                    <label>No Comments Found</label>
                </Col>
            } */}
            </div>
            {
                replyState.showReplyField ? 
                    <div className={styles.repliedChatInBackground}>
                        <FontAwesomeIcon style={{ color: '#616C76', marginRight: '4px' }} icon={faReply} />
                        <div className={styles.replyChatAndCloseIconWrapper}>
                            <span>{replyState.oldComment.substring(0, 40) + '...'}</span>
                            <CloseOutlined onClick={() => {
                                document.getElementById("submitBtn").blur();
                                setReplyState(INITIAL_REPLY_STATE);
                            }} />
                        </div>
                    </div>
                : null
            }
           {allowNewChat &&   <Row className="text-area_chat">
                
                <Textarea s={12} value={replyState.showReplyField ? replyState.comment : comment.data}
                    onFocus={() => {
                        video && !video.paused && video.pause();
                    }}
                    id="submitBtn"
                    className={styles.chatBoxInput}
                    // for track enter button
                    onKeyPress={(e) => keyPressHandler(e)}

                    onChange={e => {
                        let temp = e.target.value;
                        if (replyState.showReplyField) {
                            setReplyState(prev => ({ ...prev, comment: temp }));
                        } else {
                            setComment(prev => ({ ...prev, data: temp}))}}
                        }
                    placeholder={`Mention someone by using their specific tagname ${section === "ytHelperSection" ? "(@Admin/@YTHelper)" : "(@Admin/@Qa/@Editor/@YTHelper)"}`}

                />
             <Col className="submit_chat"><Button className={styles.sendButton} icon={<Icon className={styles.sendIcon} left>send</Icon>} onClick={() => {
                        if (replyState.showReplyField) {
                            comment.id > 0 ? editComment() : send("reply")
                        } else {
                            comment.id > 0 ? editComment() : send()
                        }
                    }} disabled={disableBtn} large flat /></Col> 
            </Row>}
        </Row>
    );
}
