export const FETCH_USER = 'fetch_user';
export const FETCHING_USER = 'fetching_user';
export const FETCHING_SUB_DOMAIN_DATA = 'fetching_sub_domain_data';
export const FETCH_SUB_DOMAIN_DATA = 'fetch_sub_domain_data';
export const USER_LOGOUT = 'user_logout';
export const TOKEN_EXPIRED = 'token_expired';

export const CARDS_FETCHING = 'cards_fetching';
export const CARDS_FETCHED = 'cards_fetched';

export const FETCHING_ALL_USERS = 'fetching_all_users';
export const FETCH_ALL_USERS = 'fetch_all_users';
export const ADD_USER = 'add_user';
export const ADD_CUSTOMER = 'add_customer';
export const REMOVE_USER = 'remove_user';
export const REMOVE_CUSTOMER = 'remove_customer';
export const REMOVE_WHITE_LABEL_CUSTOMER = 'remove_white_label_customer';
export const FETCH_ALL_CUSTOMERS = 'fetch_all_customers';
export const FETCHING_ALL_CUSTOMERS = 'fetching_all_customers';
export const FETCH_ALL_WHITE_LABEL_CUSTOMERS = 'fetch_all_white_label_customers';
export const FETCHING_ALL_WHITE_LABEL_CUSTOMERS = 'fetching_all_white_label_customers';

export const FETCHING_SETTINGS = 'fetching_settings';
export const FETCH_SETTINGS = 'fetch_settings';
export const UPDATE_SETTINGS = 'update_settings';

export const FETCH_WEBSITE_USER = 'fetch_website_user';

export const YTHELPER_CARDS_FETCHING = 'ythelper_cards_fetching';
export const YTHELPER_CARDS_FETCHED = 'ythelper_cards_fetched';

export const FETCHING_NOTIFICATION_COUNT = 'fetching_notification_count';
export const FETCH_NOTIFICATION_COUNT = 'fetch_notification_count';
export const FETCHING_NOTIFICATION_LIST = 'fetching_notification_list';
export const FETCH_NOTIFICATION_LIST = 'fetch_notification_list';

export const SEARCH_USER = "search_user";

//FOR Rating Reducers.
export const RATING_SET_INDEX = "set_index";
export const RATING_SET_START_DATE = "set_start_date";
export const RATING_SET_END_DATE = "set_end_date";
export const RATING_SET_SELECTED_RATING = "set_selected_rating";
export const UPDATED_RATING_CARDS_DATA = "updated_ratings_card_data";
export const TOTAL_RATED_CARDS = "total_rated_cards";


//For Inbox Reducers.
export const UNSEEN_INBOX_MESSAGE = "unseen_inbox_message";
export const SET_PAGE = "set_page";
export const SET_ROWS_COUNT = "set_rows_count";
export const SET_CHAT_TITLE = "set_chat_title";
export const SET_ADD_TITLE = "set_add_title";
export const Inbox_LOADING = "inbox_loading";
export const Inbox_UPDATED_COUNT = "inbox_updated_count";
export const SET_INBOX_ADD_MESSAGE = "set_inbox_add_message";
export const SET_INBOX_MESSAGE = "set_inbox_message";
export const SET_INBOX_CURRENT_SELECTED_MESSAGE = "set_inbox_current_selected_message";
export const SET_INBOX_HAS_MORE = "set_inbox_has_more";
export const INBOX_SET_STATE = "inbox_set_state";

