import React, { useEffect, useState } from "react";
import { Collapsible, CollapsibleItem, Card, Table, Icon, Button, Col, Row } from "react-materialize";
import { exportTableToCSV, getUserShortName, toISODate } from "../../api/Util";
import { randomNumber } from "react-ratings-declarative/build/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const CSATTable = ({ data, title, tab, start, end }) => {
	const [stats, setStats] = useState([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const [filter, setFilter] = useState('');
	useEffect(() => {
		setStats(Object.keys(data).filter(userId => userId !== "0" && userId !== null).map(userId => ({
			id: userId,
			name: data?.[userId]?.qa_name,
			total_rated_video: data?.[userId]?.rated_videos || 0,
			total_rating: data?.[userId]?.total_rating || 0,
			avgRating: data?.[userId]?.total_rating / Math.max(data?.[userId]?.rated_videos, 1) || 0,
			teamLeadUpdated: data?.[userId]?.teamLeadUpdated,
		})).filter(user => user?.total_rated_video > 0 || user?.total_rating > 0));
	}, [data]);

	const handleCollapsibleClick = () => {
		setIsExpanded(!isExpanded);
	};

	let tableId = title.replaceAll(/\s/g, '-') + '-' + randomNumber();
	const filteredStats = stats.filter(user => user.name?.toLowerCase()?.includes(filter.toLowerCase()));

	return (
		<Card className="csatTable dashboard-collapsible-section">
			<Collapsible accordion>
				<CollapsibleItem
					expanded={isExpanded}
					onClick={handleCollapsibleClick}
					header={(
						<div className="dashboard-collapsible-header">
							<span className="collapsible-title">{title}<FontAwesomeIcon icon="star" color="#FFD700" size="xs" title="☆" /></span>
							<Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
						</div>
					)}
				>
					<Row>
						<Col s={6}>{<Icon className="searchIconDashboard">search</Icon>}<input type="text" onChange={e => setFilter(e.target.value)} placeholder="Search By Name" className="searchInputDashboard" value={filter} /></Col>
						<Col className="exportCsvButtonDashboard"><Button large flat icon={<Icon left>save</Icon>} onClick={() => exportTableToCSV(tableId, `${title} ${toISODate(start)} - ${toISODate(end)}.csv`)}>Export as CSV</Button></Col>
					</Row>
					<Table responsive={true} hoverable={true} id={tableId}>
						<thead>
							<tr>
								<th>Name</th>
								<th className="align-center">Total Rated Video</th>
								<th className="align-center">Total Rating</th>
								<th className="align-center">Average CSAT</th>
							</tr>
						</thead>
						<tbody>
							{filteredStats.map(stat => (
								<tr key={stat.id}>
									{!stat.team_lead_updated ? (
										<Link to={`/user/${stat.id}/profile?userType=${title}&startDate=${toISODate(start)}&endDate=${toISODate(end)}`} style={{ color: 'blue', cursor: 'pointer' }} target="_blank">
											<td className="align-center">{getUserShortName(stat.name)}</td>
										</Link>
									) : (
										<td style={{ color: 'black', cursor: 'not-allowed', textDecoration: "line-through" }}>{getUserShortName(stat.name)}</td>
									)}
									<td className="align-center">{stat.total_rated_video}</td>
									<td className="align-center">{stat.total_rating}</td>
									<td className="align-center">{stat.avgRating.toFixed(2)}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</CollapsibleItem>
			</Collapsible>
		</Card>
	);
};

export default CSATTable;
