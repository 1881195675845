import React from "react";
import { Rate, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Card as MaterialCard } from "react-materialize";
import CustomDatePicker from "../common/DatePicker";
import styles from "./rating.module.scss";
import "./rating.scss";
import { RATING_SET_START_DATE, RATING_SET_END_DATE, RATING_SET_SELECTED_RATING } from "../../actions/types";

const RatingFilter = () => {
	const dispatch = useDispatch();
	const { startDate, endDate, selectedRating } = useSelector((state) => state.ratingData);
	const newOpt = [];
	for (let i = 1; i <= 5; i++) {
		newOpt.push({
			label: (
				<>
					<span style={{ display: 'inline-block', width: '20px' }}>{i}</span>
					<Rate value={i} style={{ marginLeft: '8px' }} disabled />
				</>
			),
			value: i.toString(),
		});
	}
	const handleStartDateChange = (date) => {
		dispatch({ type: RATING_SET_START_DATE, payload: date });
	};

	const handleEndDateChange = (date) => {
		dispatch({ type: RATING_SET_END_DATE, payload: date });
	};

	const handleRatingChange = (value) => {
		dispatch({ type: RATING_SET_SELECTED_RATING, payload: value });
	};

	return (
		<MaterialCard className={`${styles.materialCard} ${styles.datePickerAndSelectWrapper}`}>
			<CustomDatePicker
				className={`${styles.datePicker} ${styles.mobileMode}`}
				setStartDate={handleStartDateChange}
				setEndDate={handleEndDateChange}
				startDate={startDate}
				endDate={endDate}
			/>
			<Select
				className={`${styles.selectBox} selectBoxStyle`}
				allowClear
				placeholder="Please select rating"
				onChange={handleRatingChange}
				value={selectedRating}
				options={newOpt}
			/>
		</MaterialCard>
	);
};

export default RatingFilter;
