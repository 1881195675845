import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import * as actions from "../actions";
import M from 'materialize-css';
import {Button, Icon} from "react-materialize";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ADMIN, CUSTOMER, QA_USER, SUB_ADMIN, TEAM_LEAD, YT_HELPER, YT_HELPER_LEAD} from "../api/Constants";

import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent,Menu,MenuItem } from 'react-pro-sidebar';
import { AiOutlineCloudUpload, AiOutlineRobot, AiOutlineUserSwitch, AiOutlineYoutube, AiOutlineDashboard, AiOutlineSetting} from  'react-icons/ai';
import { HiOutlineUserGroup } from  'react-icons/hi';
import { RiArchiveDrawerLine } from  'react-icons/ri';
import { BiHomeAlt, BiStar, BiCard, BiMessageAlt } from 'react-icons/bi';

export default connect(mapStateToProps, actions)((props) => {
    const auth = props.auth;
    const unSeenMessages = props.inboxSeenMessages?.unSeenCount;
    const subDomainData = props.subDomain.subDomainData;
    let parentId = localStorage.getItem('parentId');
    document.documentElement.style.setProperty('--theme--Color', subDomainData?.primary_color);
    document.documentElement.style.setProperty('--primary-text--Color', subDomainData?.primary_text_color);

    useEffect(() => {
        console.debug(M != null);
    }, []);

    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        if (auth.loggedIn && !auth.loggedInUser && auth.loading === false) {
            props.fetchUser();
        }
        if (auth.loggedIn && !auth.loggedInUser){
            props.fetchAllWebsiteUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.loggedIn, auth.loggedInUser]);

    useEffect(() => {
        if(window.innerWidth<992)
        {
            setToggled(true);
            props.handleIsMobileView(true);
        }
        else
        {
            setToggled(false);
            props.handleIsMobileView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth])

    useEffect(() => {
        props.handleSidebarToggled(toggled)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggled]);

    function userTypeOptions(user) {
        if (parentId === '0') {
            return whiteLabelLink()
        } else {
            let clientType = (user && user.client_type) || '';
            switch(clientType) {
                case ADMIN:
                case SUB_ADMIN:
                    return adminHeaderLinks();
                case TEAM_LEAD:
                case YT_HELPER_LEAD:
                    return teamLeadHeaderLinks();
                case QA_USER:
                case YT_HELPER:
                    return qaHeaderLinks();
                case CUSTOMER:
                    return customerHeaderLinks();
                default:
                    return '';
            }
        }
    }

    function whiteLabelLink() {
        return [
            <MenuItem key="whiteLabelCustomersNav" onClick={() => props.fetchAllWhiteLabelCustomers()}>
            <NavLink to="/whitelabelcustomers">
                <HiOutlineUserGroup/>&nbsp;&nbsp;White Label Customers
            </NavLink>
            </MenuItem>,
            <MenuItem key="settingsNav">
            <NavLink to="/settings">
                {/* <FontAwesomeIcon icon="cogs"/> */}
                <AiOutlineSetting/>&nbsp;&nbsp;Portal Settings
            </NavLink>
            </MenuItem>,
        ];
    }

    function adminHeaderLinks() {
        return [
            <MenuItem key="home">
                <NavLink to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            !(subDomainData.name === 'acceleratusmedia' && props.auth.loggedInUser.client_type === SUB_ADMIN) ?
                <MenuItem key="uploadNav">
                <NavLink to="/upload">
                <AiOutlineCloudUpload/>&nbsp;&nbsp;Upload
                    {/* <FontAwesomeIcon icon="user-tie"/> */}
                </NavLink>
                </MenuItem> : null,
            props.auth.loggedInUser.client_type === ADMIN ?
            <MenuItem key="usersNav">
            <NavLink to="/users">
            <AiOutlineUserSwitch/>&nbsp;&nbsp;Users
                {/* <FontAwesomeIcon icon="user-tie"/> */}
            </NavLink>
            </MenuItem> : null,
            <MenuItem key="customersNav">
            <NavLink to="/customers">
                {/* <FontAwesomeIcon icon="users"/> */}
                <HiOutlineUserGroup/>&nbsp;&nbsp;Customers
            </NavLink>
            </MenuItem>,
            <MenuItem key = "ytHelperNav">
            <NavLink to = "/ythelper/dashboard">
                {/* <FontAwesomeIcon icon = "user"/> */}
                <AiOutlineYoutube/>&nbsp;&nbsp;YouTube Helper
            </NavLink>
            </MenuItem>,
            <MenuItem key="dueCards">
                <NavLink to="/dueCards">
                    <BiCard />&nbsp;&nbsp;Due Cards
                </NavLink>
            </MenuItem>,
            <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Support&nbsp;
                    {
                        unSeenMessages > 0 &&
                        <span className="unseenMessagesCount">
                            {unSeenMessages}
                        </span>
                    }
                </NavLink>
            </MenuItem>,
            <MenuItem key="dashboardNav">
            <NavLink to="/dashboard">
                {/* <FontAwesomeIcon icon="chart-line"/> */}
                <AiOutlineDashboard/>&nbsp;&nbsp;Dashboard
            </NavLink>
            </MenuItem>,
            <MenuItem key="archivedNav">
            <NavLink to="/cards/archive">
                {/* <FontAwesomeIcon icon="archive"/> */}
                <RiArchiveDrawerLine/>&nbsp;&nbsp;Archived
            </NavLink>
            </MenuItem>,
            <MenuItem key="ratingNav">
                <NavLink to="/rating">
                    <BiStar />&nbsp;&nbsp;Rating
                </NavLink>
            </MenuItem>,
            <MenuItem key="AIToolbox">
                <NavLink to="/AIToolbox">
                    <AiOutlineRobot />&nbsp;&nbsp;AI Toolbox
                </NavLink>
            </MenuItem>,
        ];
    }

    function teamLeadHeaderLinks() {
        return [
            <MenuItem>
                <NavLink key="home" to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem>
            <NavLink key="uploadNav" to="/upload">
                <Icon left style = {{ marginRight : "0px" }} >upload</Icon>Upload
            </NavLink>
            </MenuItem>,
            // to dispaly yt helper dashboard only to yt helper team lead
            props.auth.loggedInUser.client_type === YT_HELPER_LEAD ? 
                <MenuItem><NavLink key = "ytHelperNav" to = "/ythelper/dashboard">
                    <FontAwesomeIcon icon = "user"/>&nbsp;&nbsp;YouTube Helper
                </NavLink> </MenuItem> : null,
            <MenuItem> <NavLink key="usersNav" to="/users">
                <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;My Team
            </NavLink></MenuItem>,
            <MenuItem> <NavLink key="customersNav" to="/customers">
                <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;My Customers
            </NavLink></MenuItem>,
            <MenuItem key="dueCards">
                <NavLink to="/dueCards">
                    <BiCard />&nbsp;&nbsp;Due Cards
                </NavLink>
            </MenuItem>,
            <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Support&nbsp;
                    {
                        unSeenMessages > 0 &&
                        <span className="unseenMessagesCount">
                            {unSeenMessages}
                        </span>
                    }
                </NavLink>
            </MenuItem>,
            props.auth.loggedInUser.client_type === TEAM_LEAD ? 
                <MenuItem key="dashboardNav">
                    <NavLink to="/dashboard">
                        {/* <FontAwesomeIcon icon="chart-line"/> */}
                        <AiOutlineDashboard/>&nbsp;&nbsp;Dashboard
                    </NavLink>
                </MenuItem> : null,
                <MenuItem key="archivedNav">
                    <NavLink to="/cards/archive">
                        {/* <FontAwesomeIcon icon="archive"/> */}
                        <RiArchiveDrawerLine/>&nbsp;&nbsp;Archived
                    </NavLink>
                </MenuItem>,
        ];
    }

    function qaHeaderLinks() {
        return [
            <MenuItem>
                <NavLink key="home" to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem>
                <NavLink key="uploadNav" to="/upload">
                    <Icon left style = {{ marginRight : "0px" }} >upload</Icon>Upload
                </NavLink>
            </MenuItem>,
            // display yt helper assigned card tab only for yt helper, not for qa login
            props.auth.loggedInUser.client_type === YT_HELPER ? 
                <MenuItem><NavLink key = "ytHelperNav" to = "/ythelper/dashboard">
                    <FontAwesomeIcon icon = "user"/>&nbsp;&nbsp;YouTube Helper
                </NavLink></MenuItem> : null,
            <MenuItem> <NavLink key="customersNav" to="/customers">
                <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;My Customers
            </NavLink>
            </MenuItem>,
            <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Support&nbsp;
                    {
                        unSeenMessages > 0 &&
                        <span className="unseenMessagesCount">
                            {unSeenMessages}
                        </span>
                    }
                </NavLink>
            </MenuItem>,
            <MenuItem key="dueCards">
                <NavLink to="/dueCards">
                    <BiCard />&nbsp;&nbsp;Due Cards
                </NavLink>
            </MenuItem>,
        ];
    }

    function customerHeaderLinks() {
        return [
            <MenuItem key="home">
                <NavLink to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem key="dashboardNav">
                <NavLink to="/dashboard">
                    <AiOutlineDashboard/>&nbsp;&nbsp;Dashboard
                </NavLink>
            </MenuItem>,
            <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Support
                </NavLink>
            </MenuItem>,
            <MenuItem key="AIToolbox">
                <NavLink to="/AIToolbox">
                    <AiOutlineRobot />&nbsp;&nbsp;AI Toolbox
                </NavLink>
            </MenuItem>,
            // if customer have assigned yt helper then only show yt helper assigned card tab
            props.auth.loggedInUser.has_youtube_helper ?
                (<MenuItem key = "ytHelperNav"><NavLink to = "/ythelper/dashboard">
                    <FontAwesomeIcon icon = "user"/>&nbsp;&nbsp;YouTube Helper
                </NavLink> </MenuItem>):null,
        ];
    }
    
    return auth?.loggedIn && <ProSidebar 
        collapsed = {toggled}
    >
    
    <SidebarHeader>
        <a className={`brand-logo ${!(subDomainData?.profile_logo && subDomainData?.sidebar_logo) && "logoWrapper"}` } href={parentId !== '0' ? "/" : undefined}>
            {subDomainData?.profile_logo ? <img src={subDomainData?.profile_logo} alt="logo" className="avatar hide-on-small-only"/> : null}
            {
                subDomainData?.sidebar_logo && (subDomainData.name !== 'acceleratusmedia') && <div style={{ paddingLeft: '16px' }}>
                <img src={subDomainData?.sidebar_logo} alt="Videoportal" className="vidchops-logo"/>
            </div>
            }
        </a>
        <Button className="sidebar-toggle" onClick={()=>{
            if(toggled)
            {
                setToggled(false);
            }
            else
            {
                setToggled(true);
            }
        }} ><span className="bars first"></span>
            <span className="bars second"></span>
            <span className="bars third"></span>
        </Button>
    </SidebarHeader>
    <SidebarContent>
    <Menu iconShape="square">
        { userTypeOptions(auth?.loggedInUser) }
    </Menu>
    </SidebarContent>
    <SidebarFooter>
      {/**
       *  You can add a footer for the sidebar ex: copyright
       */}
    </SidebarFooter>
  </ProSidebar>;
   
});

function mapStateToProps({auth, subDomain, inboxSeenMessages}) {
    return {auth, subDomain, inboxSeenMessages};
}
