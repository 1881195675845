import React from 'react';
import NotificationDropdown from "../NotificationDropdown";
import {Divider, Dropdown, Navbar} from "react-materialize";
import {Link, NavLink} from "react-router-dom";
import * as actions from "../../actions";
import {connect} from 'react-redux';
import { BiUser } from  'react-icons/bi';
import { FiLogOut } from  'react-icons/fi';
import styles from './TopHeaderStyle.module.scss';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';



export default connect(mapStateToProps, actions)((props) => {
    const auth = props.auth;
    function profile() {
        if (auth.loggedIn && auth.loggedInUser) {
            let fullName = auth.loggedInUser.firstname + " " + auth.loggedInUser.lastname;
            return (
                <Dropdown
                    id="profileDropdown"
                    className='customDropdown'
                    options={{ coverTrigger: false }}
                    trigger={<a href="/#" className="center-align">
                        <div>
                            <Avatar className={styles.profileLogo} icon={<UserOutlined />} />
                            {/* <img src={auth.loggedInUser.profile_img || Profile} className="profile-image hide-on-small-only" alt="Avatar"/> */}
                            <span className="profile-pic" style={{verticalAlign: "middle"}}>{fullName}</span>
                        </div>
                    </a>}
                >
                    <Link to={"/profile"}>
                    <BiUser/>{fullName}
                    </Link>
                    <Divider />
                    <a href="/#" onClick={props.logout.bind(this)}>
                    <FiLogOut/>Logout
                    </a>
                </Dropdown>
            );
        } else {
            return [
                <NavLink key="loginNav" to="/login">
                    Login
                </NavLink>,
                <NavLink key="signupNav" to="/signup">
                    Signup
                </NavLink>
            ];
        }
    }

    return (
        <div className={styles.topHeaderContainer}>
            { auth?.loggedIn && <NotificationDropdown me={auth?.loggedInUser}/> }
            <Navbar 
                className='sideBaruser d-inline-flex'
            // className="sidebar"
            // alignLinks = 'left'
            // id="headerNavbar"
            // menuIcon={<Icon>menu</Icon>}
            options={{
                draggable: false,
                edge: 'left',
                inDuration: 250,
                outDuration: 200,
                preventScrolling: true
            }}
            >
                { profile() }
            </Navbar>
        </div>
    )
});
function mapStateToProps({auth}) {
    return {auth};
}
