import { axiosClient } from '../api/httpClient';

export const exportCommentsFromCard = async (item, extraParams) => {
    const {tab, queryParams, type, startDate, endDate } = extraParams;
    let endpoint = tab === 'YT HELPER RESPONSE RATE' ? `/api/ythelper/card/${item.card_id}/exportComments` : `/api/card/${item.card_id}/exportComments`;
    try {
        const response = await axiosClient.get(endpoint, {
            params: {
                type: tab === 'YT HELPER RESPONSE RATE' ? 'yt_helper_comments' : 'comments',
                profileType: queryParams?.queryUserType === 'TeamLeadTab' ? 'team-lead' :
                            queryParams?.queryUserType === 'QaTab' ? 'qa' : type,
                cardIds: item.cardIds,
                start_date: startDate,
                end_date: endDate
            }
        });
        return {
            status: true,
            data: response.data
        };
    } catch (error) {
        console.error(`Unable to load comments: ${error}`);
        return {
            status: false,
            error: "Unable to load comments"
        };
    }
};
