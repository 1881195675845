import React, { useEffect, useState } from "react";
import { Button, Card, Col, Icon, ProgressBar, Row, Table, Collapsible, CollapsibleItem, } from "react-materialize";
import { exportTableToCSV, getUserShortName, onSortTable, toISODate } from "../api/Util";
import { randomNumber } from "react-ratings-declarative/build/utils";

export default ({ data, start, end, title, tab }) => {
    const [filter, setFilter] = useState('');
    const [stats, setStats] = useState([]);
    const [sortBy, setSortBy] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setStats(Object.keys(data || {})
            .map(userId => ({
                id: userId,
                name: data?.[userId]?.qa_name,
                total_requests: data?.[userId]?.total_request || 0,
                video_requests: data?.[userId]?.video_request || 0,
                inProgress: data?.[userId]?.in_progress || 0,
                onTime: data?.[userId]?.on_time || 0,
                overDue: data?.[userId]?.overdue || 0,
                revisionsOverDue: data?.[userId]?.revision_overdue || 0,
                completed: data?.[userId]?.completed || 0,
                avgHours: (data?.[userId]?.average_time / Math.max(1, data?.[userId]?.average_video_count)) || 0,
                ytAvgHours: (data?.[userId]?.yt_average_time / Math.max(1, data?.[userId]?.yt_average_video_count)) || 0,
                revisions: data?.[userId]?.revision_count || 0,
                ytRevisions: data?.[userId]?.yt_revision_count || 0,
                revisionsAvgHours: (data?.[userId]?.revision_average_time / Math.max(1, data?.[userId]?.revision_average_video_count)) || 0,
                ytRevisionsAvgHours: (data?.[userId]?.yt_revision_average_time / Math.max(1, data?.[userId]?.yt_revision_average_video_count)) || 0,
                avgRating: data?.[userId]?.total_rating / Math.max(data?.[userId]?.rated_videos, 1) || 0,
                ytAvgRating: data?.[userId]?.yt_total_rating / Math.max(data?.[userId]?.yt_rated_videos, 1) || 0,
                ratings: data?.[userId]?.rated_videos || 0,
                ytRatings: data?.[userId]?.yt_rated_videos || 0,
                teamLeadUpdated: data?.[userId]?.teamLeadUpdated,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    let tableId = title.replaceAll(/\s/g, '-') + '-' + randomNumber();

    const handleCollapsibleClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Card className="dashboard-collapsible-section">
            <Collapsible accordion>
                <CollapsibleItem
                    expanded={isExpanded}
                    onClick={handleCollapsibleClick}
                    header={(
                        <div className="dashboard-collapsible-header">
                            <span className="collapsible-title">{title}</span>
                            <Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
                        </div>
                    )}
                >
                    <Row>
                        <Col s={6}>{<Icon className="searchIconDashboard">search</Icon>}<input type="text" onChange={e => setFilter(e.target.value)} placeholder="Search By Name" className="searchInputDashboard" value={filter} /></Col>
                        <Col className="exportCsvButtonDashboard"><Button large flat icon={<Icon left>save</Icon>} onClick={() => exportTableToCSV(tableId, `${title} ${toISODate(start)} - ${toISODate(end)}.csv`)}>Export as CSV</Button></Col>
                    </Row>
                    <Table responsive={true} hoverable={true} id={tableId}>
                        <thead>
                            <tr>
                                <th className="align-left" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'name', sortBy, setSortBy)}>Name
                                </th>
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'totalRequests', sortBy, setSortBy)}>Total Requests
                                </th>
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'videoRequests', sortBy, setSortBy)}>Pending Requests
                                </th>
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'inProgress', sortBy, setSortBy)}>In Progress
                                </th>
                                {
                                    tab !== "YTHelperTab" ?
                                        [
                                            <th className="align-center" style={{ cursor: "pointer" }}
                                                onClick={() => onSortTable(stats, setStats, 'onTime', sortBy, setSortBy)}>On Time
                                            </th>,
                                            <th className="align-center" style={{ cursor: "pointer" }}
                                                onClick={() => onSortTable(stats, setStats, 'overDue', sortBy, setSortBy)}>Overdue
                                            </th>,
                                            <th className="align-center" style={{ cursor: "pointer" }}
                                                onClick={() => onSortTable(stats, setStats, 'revisionsOverDue', sortBy, setSortBy)}>Revisions Overdue
                                            </th>
                                        ] : null
                                }
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'completed', sortBy, setSortBy)}>Completed
                                </th>
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'avgHours', sortBy, setSortBy)}>Average Time
                                </th>
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'revisions', sortBy, setSortBy)}>Revisions
                                </th>
                                <th className="align-center" style={{ cursor: "pointer" }}
                                    onClick={() => onSortTable(stats, setStats, 'revisionsAverageTime', sortBy, setSortBy)}>Revisions Average Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stats.filter(user => user.name?.toLowerCase()?.includes(filter.toLowerCase()))
                                    .map(user => <tr key={user.id + "-row"}>
                                        {(tab === 'QaTab' && !user.teamLeadUpdated) || tab === 'TeamLeadTab' || tab === 'YTHelperTab' ? (
                                            <td className="align-center">{getUserShortName(user.name)}</td>
                                        ) : (tab === 'customerTab') ? (
                                            <td className="align-center">{user.name}</td>
                                        ) : (
                                            <td className="align-center" style={{ color: 'black', cursor: 'not-allowed', textDecoration: "line-through" }}>{getUserShortName(user.name)}</td>
                                        )}

                                        <td className="align-center">{user.total_requests}</td>
                                        <td className="align-center">{user.video_requests}</td>
                                        <td className="align-center">{user.inProgress}</td>
                                        {
                                            tab !== "YTHelperTab" ?
                                                [
                                                    <td className="align-center">{user.onTime}</td>,
                                                    <td className="align-center">{user.overDue}</td>,
                                                    <td className="align-center">{user.revisionsOverDue}</td>
                                                ] : null
                                        }
                                        <td className="align-center">{user.completed}</td>
                                        {
                                            tab === "YTHelperTab" ?
                                                [
                                                    <td className="align-center"><ProgressBar progress={(100.0 - ((48.0 - user.ytAvgHours) / 48.0) * 100)} />{user.ytAvgHours.toFixed(2)} hrs</td>,
                                                    <td className="align-center">{user.ytRevisions}</td>,
                                                    <td className="align-center"><ProgressBar progress={(100.0 - ((48.0 - user.ytRevisionsAvgHours) / 48.0) * 100)} />{user.ytRevisionsAvgHours.toFixed(2)} hrs</td>,
                                                ]
                                                : [
                                                    <td className="align-center"><ProgressBar progress={(100.0 - ((48.0 - user.avgHours) / 48.0) * 100)} />{user.avgHours.toFixed(2)} hrs</td>,
                                                    <td className="align-center">{user.revisions}</td>,
                                                    <td className="align-center"><ProgressBar progress={(100.0 - ((48.0 - user.revisionsAvgHours) / 48.0) * 100)} />{user.revisionsAvgHours.toFixed(2)} hrs</td>,
                                                ]
                                        }
                                    </tr>)
                            }
                        </tbody>
                    </Table>
                </CollapsibleItem>
            </Collapsible>
        </Card>);
}
