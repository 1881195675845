import { axiosClient } from '../api/httpClient';

export const getDashboardDataBasedOnDate = async (obj) => {
    try {
        const data = await axiosClient.get('/api/dashboard-data-V2', { params: obj });
        return {
            status: true,
            data: data?.data
        };
    } catch (error) {
        return {
            status: false,
            error: "Some Error Occurred"
        };
    }
};

export const getDashboardDataForCustomer = async (obj) => {
    try {
        const data = await axiosClient.get('/api/client-dashboard-data', { params: obj });
        return {
            status: true,
            data: data?.data
        };
    } catch (error) {
        return {
            status: false,
            error: "Some Error Occurred"
        };
    }
};

export const getARTData = async (obj) => {
    try {
        const data = await axiosClient.get('/api/art-dashboard-data', { params: obj });
        return {
            status: true,
            data: data?.data
        };
    } catch (error) {
        return {
            status: false,
            error: "Some Error Occurred"
        };
    }
};
