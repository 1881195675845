import React from "react";
import { Table, Row, Card as MaterialCard } from "react-materialize";
import Pagination from "react-js-pagination";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import styles from "./rating.module.scss";
import "./rating.scss";
import { RATING_SET_INDEX } from "../../actions/types";

const RatingTable = () => {
  const dispatch = useDispatch();
  const { cardData, totalRatedCards, index, limit } = useSelector((state) => state.ratingData);

  const handlePageChange = (newIndex) => {
    dispatch({ type: RATING_SET_INDEX, payload: newIndex });
  };

  return (
    <MaterialCard className={styles.materialCard}>
      <Table className={styles.ratingCardsTable}>
        <thead>
          <tr>
            <th className="align-center">SR. Number</th>
            <th className="align-center">Card Title</th>
            <th className="align-center">Rating</th>
            <th className="align-center">QA Name</th>
            <th className="align-center">Reason</th>
            <th className="align-center">Created Date</th>
          </tr>
        </thead>
        <tbody>
          {cardData && cardData.length > 0 ? (
            cardData.map((item, i) => (
              <tr key={`rating-card-table-row-${i}`}>
                <td className="align-center" width="10%">{(index - 1) * limit + i + 1}</td>
                <td className="align-center" width="30%">{item.card_title}</td>
                <td className="align-center" width="10%">{item.rating}</td>
                <td className="align-center" width="10%">{item.qaFirstname} {item.qaLastname}</td>
                <td className="align-center" width="30%">{item.reason}</td>
                <td className="align-center" width="10%">{item.created_at}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="align-center" colSpan="6">No data found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {totalRatedCards > 0 && (
        <Row className="center-align">
          <Pagination
            activePage={index}
            itemsCountPerPage={10}
            totalItemsCount={totalRatedCards}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
            prevPageText={<AiOutlineLeft />}
            nextPageText={<AiOutlineRight />}
            firstPageText={<AiOutlineDoubleLeft />}
            lastPageText={<AiOutlineDoubleRight />}
          />
        </Row>
      )}
    </MaterialCard>
  );
};

export default RatingTable;
