import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as actions from "../actions";
import Clock from 'react-live-clock';
import {
    Button,
    Card,
    CardPanel,
    Checkbox,
    Col,
    Collapsible,
    CollapsibleItem,
    Container,
    Dropdown,
    Icon,
    ProgressBar,
    Row,
    Modal,
} from "react-materialize";
import Select from 'react-select';
import * as _ from 'lodash';
import CardList from "./CardList";
import CreateCard from "./CreateCard";
import CardModal from "./CardModal";
import EditCard from "./EditCard";
import { cssStringToObj, decodeBase64, isAdmin, isSubAdmin, isCustomer, isTeamLead, toISODate, getUserShortName } from "../api/Util";
import {CANCELED, CUSTOMER, DONE, EDITING, ON_HOLD, QA, REPURPOSE_QA, QA_USER, VIDEO_REQUEST, YT_HELPER, SUB_ADMIN} from "../api/Constants";
import DragNDrop from "./DragNDrop";
import PubSub from 'pubsub-js';
import {CARD_CLICKED} from "../api/Events";
import {useLocation} from "react-router-dom";
import SearchBar from './SearchBar';

import TopHeader from './TopHeader';
import {DateRangePicker} from 'react-date-range';
import { fetchCardsBasedOnSection } from '../services/cardsService';
import { useDispatch } from 'react-redux';
import { CARDS_FETCHED } from '../actions/types';
import RenderHtml from './common/RenderHtml';


export default connect(mapStateToProps, actions)((props) => {

    const [key, setKey] = useState(1);
    const [newCardRequestOpen, setNewCardRequestOpen] = useState(false);
    const [viewCardOpen, setViewCardOpen] = useState(false);
    const [editCardOpen, setEditCardOpen] = useState(false);
    const [viewCardId, setViewCardId] = useState(0);
    const [editCardId, setEditCardId] = useState(0);
    const [viewAs, setViewAsState] = useState(null);
    const [showCanceled, setShowCanceled] = useState(false);
    const [showHold, setShowHold] = useState(true);
    const [showRepurposed, setShowRepurposed] = useState(true);
    const location = useLocation();
    const [searchText, setSearchText] = useState();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedDueDate, setSelectedDueDate] = useState(false);
    const [cardsData, setCardsData] = useState({});
    const [addDateRangeModalOpen, setAddDateRangeModalOpen] = useState(false);
    const [clearFilterState, setClearFilterState] = useState(false);
    const [showBanner, setShowBanner] = useState({});
    const loggedInUser = props.auth?.loggedInUser || {};

    const admin = isAdmin(loggedInUser);
    const subAdmin = isSubAdmin(loggedInUser);
    const customer = isCustomer(loggedInUser);
    const teamLead =isTeamLead(loggedInUser);
    const websiteName = props.subDomain.subDomainData.name;
    const websiteSlug = props?.subDomain?.subDomainData?.website_slug;
    const dispatch = useDispatch();

    const requestsLimited = customer && loggedInUser.request_limit;
    const accountPending = customer && loggedInUser.qas?.length <= 0;

    const showProfileMsg = localStorage.getItem("skipProfileForNow");
    const parentId = localStorage.getItem("parentId");
    function setViewAs(v) {
        setViewAsState(v);
        refresh(v.value);
    }

    const refresh = (user) => {
        setSearchText("");
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name:"",
        }

        if(startDate && endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(startDate),
                endDate:toISODate(endDate),
                selectedDueDate
            }
        }

        props.fetchCards(obj,user || viewAs?.value);
    };
    const showCreateCard = () => {
        if(!(requestsLimited || accountPending)) {
            setNewCardRequestOpen(true);
        }
    }
    const showEditCard = (cardId) => {
        setEditCardId(cardId);
        setEditCardOpen(true);
    }
    const showCard = (cardId) => {
        setViewCardId(cardId);
        setViewCardOpen(true);
    }

    const searchCards = () =>{
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name: searchText ? searchText:"",
        }
        props.fetchCards(obj,viewAs?.value);
    }

    useEffect(() => {
        if (props.location.search) {
            let encodeCardId = props.history.location.search.slice("?cardId=".length);
            let cardId = decodeBase64(encodeCardId);
            showCard(cardId);
        }
    }, [props])

    useEffect(() => {
        let cardId = location.hash?.replace(/#card-/i, "");
        if (cardId && (props?.cards?.cardMap || {})[cardId]) {
            showCard(cardId);
        }
    }, [props.cards.cardMap, location.hash]);

    useEffect(() => {
        const subToken = PubSub.subscribe(CARD_CLICKED, (msg, data) => showCard(data));

        if(_.isEmpty(props.settings)) {
            props.fetchSettings();
        }

        if(((admin &&  parentId !== '0') || (teamLead & parentId !== '0') ) && ((props.users.qas == null) && (parentId !== '0')) && ((!props.users.loadingUsers) && (parentId !== '0'))) {
            props.fetchAllUsers();
        }
        if (((admin &&  parentId !== '0') || (subAdmin && parentId !== '0' ) || (teamLead && parentId !== '0') ) && ((props.users.customers === undefined) && (parentId !== '0')) && ((!props.users.loadingCustomers) && (parentId !== '0'))) {
            props.fetchAllCustomers();
        }
        return () => PubSub.unsubscribe(subToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewAs, admin, subAdmin]);

    useEffect(() => {
        if ((startDate && endDate) || clearFilterState) {
            refresh();
            setClearFilterState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate,endDate,selectedDueDate, viewAs])

    function onDateChange(dates) {
        setStartDate(dates.selection.startDate);
        setEndDate(dates.selection.endDate);
    }

    const clearFilter = () => {
        setStartDate();
        setEndDate();
        setViewAsState(null);
        refresh();
        setClearFilterState(true);
    }

    const bodyFontStyle = {
        fontWeight: showBanner?.banner_font_style === "b" ? "bold" : "normal",
        fontStyle: showBanner?.banner_font_style === "i" ? "italic" : "normal",
        fontSize: showBanner?.banner_text_style,
        color: "#000000"
      }
      const headingStyle = {
        color: showBanner?.banner_heading_color || "#ffffff",
      };
      const getBannerHeading = (bannerHeadingStyle, bannerHeading) => {
        const headingTag = `${bannerHeadingStyle}`;
        return React.createElement(headingTag, {
          style: { ...bodyFontStyle, ...headingStyle },
          children: bannerHeading,
        });
      };
      
      useEffect(() => {
        const settingsArray = Object.values(props.settings || {});
        const enableBanner = settingsArray?.filter(
          (setting) => setting?.enable_banner === 1
        );
        const filterData = enableBanner?.find((item) => {
          let announcementStartDate = item?.startDate;
          let announcementEndDate = item?.endDate;
          let currentDate = toISODate(new Date());
          if (
            currentDate >= announcementStartDate &&
            currentDate <= announcementEndDate
          ) {
            return item;
          }
        });
        setShowBanner(filterData);
      }, [props.settings]);
    
      const bannerTextStyle = Object.assign(
        cssStringToObj(showBanner?.banner_text_style || ""),
        { whiteSpace: "pre-wrap" }
      );
    

    useEffect(() => {
        let cardStatus = [
            { status: "video_request", key: "video_requests" },
            { status: "on_hold", key: "on_hold" },
            { status: "editing", key: "editing" },
            { status: "qa", key: "qa_cards" },
            { status: "done", key: "done_cards" },
            { status: "canceled", key: "cancelled_cards" },
            { status: "repurposeEditing", key: "repurposeEditing" },
            { status: "repurposeQA", key: "repurposeQA" },
            { status: "repurposeDone", key: "repurposeDone" },
        ];
        cardStatus && cardStatus.map((data, i) => {
            let obj = {
                offset : 0,
                limit:10,
                section : data.status,
                card_name:"",
            }
            getCards(obj, data.key);
        })
    }, [])
    

    async function getCards(obj, key) {
        const res = await fetchCardsBasedOnSection(obj);
        setCardsData(prevState => ({...prevState, [key]: res.data[key]}));
    }

    useEffect(() => {
        if (Object.keys(cardsData).length > 0) {
            dispatch({
                type: CARDS_FETCHED,
                payload: { status: 200, data: cardsData }
            })
        }
    }, [cardsData])

    return (
        <Container>
            <DragNDrop loggedInUser={loggedInUser} refresh={refresh} setKey={setKey}/>
            <CardModal cardId={viewCardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} refresh={refresh}
                       qas={props.users.qas} users = {props.users.users} me={loggedInUser} />
            <EditCard cardId={editCardId} me={loggedInUser} isOpen={editCardOpen} onClose={() => setEditCardOpen(false)}/>
            <CreateCard me={loggedInUser} isOpen={newCardRequestOpen} onClose={() => {setNewCardRequestOpen(false); refresh();}}/>
            {props.cards.loading ? <Row><ProgressBar /></Row> : ""}
            {
            showBanner &&
            <Collapsible style={{ margin: 0 }}>
                <CollapsibleItem
                    className="notification-collapsible"
                    expanded={true}
                    header={'Announcement'}
                    icon={<Icon>arrow_drop_down</Icon>}
                    node="div"
                    style={{ margin: 0 }}
                >
                    <Row style={{ margin: 0}}>
                        <CardPanel className="bg-warning z-depth-4" style={{ margin: 0}}>
                            <Row>
                                <Col s={12}>
                                  <RenderHtml bannerHeading={showBanner?.banner_heading} bannerHeadingTag={showBanner?.banner_heading_style} headingStyle={headingStyle} />
                                </Col>
                            </Row>
                            <Row>
                                <Col s={12} style={bannerTextStyle}>
                                    <p style={bodyFontStyle}>{showBanner?.banner_text || ""}</p>
                                </Col>
                            </Row>
                            {
                                showBanner?.banner_image &&
                                <Row>
                                    <Col s={12}>
                                        <img src={showBanner?.banner_image} alt="Banner"
                                            style={Object.assign({width: "100%", height: "auto"},
                                                cssStringToObj(showBanner?.banner_image_style))}/>
                                    </Col>
                                </Row>
                            }
                        </CardPanel>
                    </Row>
                </CollapsibleItem>
            </Collapsible>
          }
            {
                showProfileMsg && loggedInUser.is_yt_fields_filled === 1 ? <Col className = "profile-note-col">
                    <strong className = "profile-note">Note : </strong>
                    <span className = "profile-note-message">Please update your YouTube profile section. This will help us to create better content for your YouTube channel.   </span>
                </Col>
            : null
            }
            <div className="aside-right-top-bar">
                <div className="top-bar-left">
                    <div className="refresh-create-btn">
                        <Button icon={<Icon>refresh</Icon>} tooltip="Reload Cards" onClick={()=> refresh()}
                                    className="btn btn-primary" />
                        
                        {
                            (admin || subAdmin || customer) &&
                                <Button icon={<Icon>add</Icon>} onClick={showCreateCard}
                                        tooltip={requestsLimited ? `You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.`
                                            : accountPending ? "We are setting up your account for file sharing, we will update you via email once it is done."
                                                : "Create New Video Request"} 
                                        className={"btn btn-primary" + ((requestsLimited || accountPending)
                                            ? "btn-secondary" : "btn-primary")}/> 
                        }
                    </div>
                </div>
                <div className="top-bar-center">
                <div className="top_bar">
                        <SearchBar cards = {props.cardList} setSearchText = {setSearchText} searchText = {searchText} searchCards ={searchCards} />
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <div className="aside-top-filters">
            <Row className={`hide-on-med-and-down difplay_flext ${websiteSlug}`}>
                <Col className="date-filter-col">
                    <Modal
                        trigger={(
                            <Button node="button" className="date-btn" icon={<Icon left>date_range</Icon>}>
                                {toISODate(startDate)} ~ {toISODate(endDate)}
                            </Button>
                        )}
                        header="Add Date Range"
                        id="addDateRangeModal"
                        open={addDateRangeModalOpen}
                        style={{ height: '65%', width:'51%' }}
                        options={{
                            dismissible: true,
                            endingTop: '10%',
                            opacity: 0.5,
                            preventScrolling: true,
                            onCloseEnd: () => setAddDateRangeModalOpen(false)
                        }}
                    >
                        <DateRangePicker
                            ranges={[{
                                startDate: startDate ,
                                endDate : endDate ? endDate : new Date(),
                                key: 'selection',
                            }]}
                            direction="horizontal"
                            fixedHeight={true}
                            preventSnapRefocus={true}
                            months={2}
                            onChange={onDateChange} />
                            <Card className="due-date-card">
                                <Checkbox 
                                    value="2"
                                    label="Due Data" 
                                    checked={selectedDueDate} 
                                    onChange ={()=>setSelectedDueDate(!selectedDueDate)}
                                />
                            </Card>
                    </Modal>
                </Col>
                {
                    (admin || subAdmin || isTeamLead(loggedInUser)) &&
                    <Col className="view-input-col">
                        <Select className="z-depth-1 select-view-as"
                            value={viewAs}
                            onChange={setViewAs}
                            options={_.concat([{...loggedInUser, fullname: 'Me'}], 
                            props.users?.users?.filter(user => user.client_type === QA_USER || user.client_type === SUB_ADMIN || user.client_type === YT_HELPER ) || [], props.users?.customers || [])
                                .map(user => ({value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`
                            }))
                            }
                            placeholder="View As"
                        />
                    </Col>
                }
                <Col>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                                  onChange={setShowCanceled.bind(this, Boolean(!showCanceled))}/>
                    </CardPanel>
                </Col>
                <Col>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
                                  onChange={setShowHold.bind(this, Boolean(!showHold))}/>
                    </CardPanel>
                </Col>
                <Col>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="2" label="Show Cards In Repurpose" checked={showRepurposed} id="check-rp"
                                  onChange={setShowRepurposed.bind(this, Boolean(!showRepurposed))}/>
                    </CardPanel>
                </Col>
                <Col className="real-date">
                    <CardPanel style={{margin: 0, borderRadius: "8px"}}>
                        <Icon left>access_time</Icon>
                        <Clock
                            format={"dddd, DD-MMM-YYYY | hh:mm:ss"}
                            ticking={true}
                            interval={1000}
                            timezone={websiteSlug === "snappyads" ? process.env.REACT_APP_TIMEZONE_SNAPPY : process.env.REACT_APP_TIMEZONE}
                        />
                        <span>{websiteSlug === "snappyads" ? "GMT" : "EST"}</span>
                    </CardPanel>
                </Col>
                {
                    customer && (props.cards.monthlyCardCount || props.cards.monthlyCardCount === 0) ? <Col>
                        <CardPanel className='month-card-created-cardpanel'>
                            <span>Monthly Card Created Count : </span>
                            <span style={{ margin: '0 4px 0px 4px' }}>{props.cards.monthlyCardCount} </span>
                        </CardPanel>
                    </Col>: null
                }
                <Col className="cutomer-filter-clear-btn">
                    <Button className='clear-filter-btn' style={{ height: '44px' }} onClick={()=> clearFilter()}>Clear Filter</Button>
                </Col>
              
            </Row>
            <Row className="hide-on-large-only hide-on-extra-large-only mobile-control-btns">
                <Col s={2} m={4}>
                    <Dropdown
                        trigger={<Button style={{backgroundColor: "#82b150", marginTop: "5px"}} floating large icon={<Icon>more_vert</Icon>}/>}
                        options={{
                            autoTrigger: false,
                            constrainWidth: false,
                            coverTrigger: false,
                            closeOnClick: false,
                        }}>
                        {React.Children.toArray([
                            (admin || subAdmin || customer) && !(requestsLimited || accountPending) ?
                            <Button icon={<Icon left>add</Icon>} flat onClick={showCreateCard} style={{margin: '5px'}}>
                                Create New Request</Button> : null,
                            accountPending ?
                                <p style={{margin: '20px'}}>Your DropBox video account is in progress, we will send you an email when we are done then you will be able create video request.</p>
                                : null,
                            requestsLimited ?
                                <p style={{margin: '20px'}}>You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.</p>
                                : null,
                            <Button icon={<Icon left>refresh</Icon>} flat onClick={()=> refresh()} style={{margin: '5px'}}>Refresh</Button>,
                            <div style={{margin: '20px'}}>
                                <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                                          onChange={setShowCanceled.bind(this, Boolean(!showCanceled))}/>
                            </div>,
                            <div style={{margin: '20px'}}>
                                <Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
                                          onChange={setShowHold.bind(this, Boolean(!showHold))}/>
                            </div>,
                            <div style={{margin: '20px'}}>
                                <Checkbox value="2" label="Show Cards In Repurpose" checked={showHold} id="check-repurp-2"
                                          onChange={setShowRepurposed.bind(this, Boolean(!showRepurposed))}/>
                            </div>,
                            (admin || subAdmin || isTeamLead(loggedInUser)) &&
                            <Select
                                value={viewAs}
                                onChange={setViewAs}
                                options={_.concat([{...loggedInUser, fullname: 'Me'}], 
                                props.users?.users?.filter(user => user.client_type === QA_USER || user.client_type === SUB_ADMIN || user.client_type === YT_HELPER) || [], props.users?.customers || [])
                                    .map(user => ({value: user.client_id, label: `${user.fullname} (${user.client_type})`}))
                                }
                                placeholder="View As"
                            />,
                        ])}
                    </Dropdown>
                </Col>
                <Col s={10} m={8}>
                    <CardPanel style={{margin: 0, fontSize: "1.2rem"}}>
                        <Icon left>access_time</Icon>
                        <Clock
                            format={"DD-MMM-YYYY hh:mm:ss"}
                            ticking={true}
                            interval={1000}
                            timezone={websiteSlug === "snappyads" ? process.env.REACT_APP_TIMEZONE_SNAPPY : process.env.REACT_APP_TIMEZONE}
                        />
                        <span>{websiteSlug === "snappyads" ? "GMT" : "EST"}</span>
                    </CardPanel>
                </Col>
            </Row>
        </div>
       
            <Row className="row right-sec-row">
                <Col s={12} m={12}>
            {/* 4 columns --- */}
            <div className="card-rows">
            <Row className="video-rows" style={{overflowX: "scroll", marginBottom: "0", }}>
                    <Col className="mobile-card-rows" s={4} m={4}>
                        <Collapsible className={`video-requests-list ${websiteName} filters-icon`} accordion>
                            <CardList name="Video Requests" cards={props.cards?.cardList?.video_requests}  viewAs = {viewAs?.value}
                                    viewCard={customer ? showEditCard : showCard} key={"vr-" + key} searchText = {searchText}
                                    startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    type={VIDEO_REQUEST}/>
                        </Collapsible>
                    </Col>
                {
                    showHold &&
                    <Col className="mobile-card-rows" s={4} m={4} >
                        <Collapsible className={`hold-list ${websiteName} filters-icon`} accordion>
                                <CardList name="On Hold" viewCard={showCard} cards={props.cards?.cardList?.on_hold} viewAs = {viewAs?.value}
                                            type={ON_HOLD} key={"oh-" + key} searchText = {searchText}
                                            startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                            </Collapsible>
                    </Col>
                }
                <Col className="mobile-card-rows" s={4} m={4} >
                    <Collapsible className={`editing-list ${websiteName} filters-icon`} accordion>
                        <CardList name="Editing" viewCard={showCard} cards={props.cards?.cardList?.editing} viewAs = {viewAs?.value}
                                    type={EDITING} key={"ed-" + key} searchText = {searchText}
                                    startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                    </Collapsible>
                </Col>
                {
                    !(websiteName === 'vipleadmachine' && customer) ? <Col className="mobile-card-rows" s={4} m={4} >
                    <Collapsible className={`qa-list ${websiteName} filters-icon`} accordion>
                        <CardList name={websiteName === 'snappyAds' ? "Quality Control" : "QA"}  viewCard={showCard} cards={props.cards?.cardList?.qa_cards} viewAs = {viewAs?.value}
                                    type={QA} key={"qa-" + key} searchText = {searchText}
                                    startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                    </Collapsible>
                </Col> : null
                }

                <Col className="mobile-card-rows" s={4} m={4} >
                    <Collapsible className={`done-list ${websiteName} filters-icon`} accordion>
                            <CardList name="Done" viewCard={showCard} cards={props.cards?.cardList?.done_cards} viewAs = {viewAs?.value}
                                        type={DONE} key={"dn-" + key} searchText = {searchText} 
                                        startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                    </Collapsible>
                </Col>
                {
                    showCanceled &&
                    <Col className="mobile-card-rows" s={4} m={4} >
                        <Collapsible className={`canceled-list ${websiteName}`} accordion>
                                    <CardList name="Canceled" viewCard={showCard} cards={props.cards?.cardList?.cancelled_cards} viewAs = {viewAs?.value}
                                                type={CANCELED} key={"cn-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                        </Collapsible>
                    </Col>
                }
                 {
                    showRepurposed ? React.Children.toArray([
                        <Col className="mobile-card-rows" s={4} m={4} >
                                <Collapsible className={`repurpose-list ${websiteName} filters-icon`} accordion>
                                    <CardList name="Repurposing" viewCard={showCard}
                                                cards={props.cards?.cardList?.repurposeEditing} viewAs = {viewAs?.value}
                                                rType={EDITING} key={"red-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                                </Collapsible>
                                </Col>,
                                !(websiteName === 'vipleadmachine' && customer) &&  <Col className="mobile-card-rows" s={4} m={4} style={{ display: !(parentId > 0) && 'none' }} >
                                {parentId > 0 ?
                                <Collapsible className={`repurpose-list ${websiteName} filters-icon`} accordion>
                                    <CardList name="Repurposing QA" viewCard={showCard}
                                                cards={props.cards?.cardList?.repurposeQA} viewAs = {viewAs?.value}
                                                rType={REPURPOSE_QA} key={"red-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                                </Collapsible>
                                : null}
                            </Col>,
                            <Col className="mobile-card-rows" s={4} m={4} >
                                <Collapsible className={`repurpose-list ${websiteName} filters-icon`} accordion>
                                    <CardList name="Repurposing Done" viewCard={showCard}
                                                cards={props.cards?.cardList?.repurposeDone} viewAs = {viewAs?.value}
                                                rType={DONE} key={"rdn-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                                </Collapsible>
                                </Col>,
                        ]) : null
                 }
                 
            </Row>
            </div>
            </Col>
            </Row>
            </Container>
    );
});

function mapStateToProps({cards, auth, subDomain, users, settings}) {
    return {cards, auth, subDomain, users, settings};
}
