import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AIEditor = ({ item, onClose, editorValue, setEditorValue }) => {
  useEffect(() => {
    if (item) {
      const plainText = item.data || "";
      const htmlContent = `<div>${plainText.replace(/\n/g, "<br/>")}</div>`;
      setEditorValue(htmlContent);
    }
  }, [item, setEditorValue]);

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };

  return (
    <div className="AITextEditor">
      <ReactQuill className="AIquillEditor" value={editorValue} onChange={handleEditorChange} />
    </div>
  );
};

export default AIEditor;
