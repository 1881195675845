import React, { useEffect, useState } from 'react';
import { Typography, Card, Row, Col, Avatar, Empty, Button } from 'antd';
import styles from './NotificationListingStyle.module.scss';
import SelectedIcon from '../../../components/img/selected_icon.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { convertServerToLocalTime } from '../../../api/Util';
import NotificationDrawer from './NotificationDrawer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
const NotificationListing = ({
	notifications,
	isLoading,
	setAllNotifications,
	selectedItems,
	setSelectedItems,
	isMobile,
	handleMarkAsRead,
}) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [uniqueId, setUniqueId] = useState(null);
	const singleAndMultiSelect = (item) => {
		if (item.selected) {
			item.selected = 0;
		} else {
			item.selected = 1;
		}
		if (item.selected) {
			setSelectedItems([...selectedItems, item]);
		} else {
			const indexOfObject = selectedItems.findIndex(object => {
				return object.id === item.id;
			});
			if (indexOfObject > -1) {
				selectedItems.splice(indexOfObject, 1);
				if (selectedItems.length === 0) {
					setSelectedItems([]);
				} else {
					setSelectedItems([...selectedItems]);
				}
			} else {
				setSelectedItems([]);
			}
		}
		const indexOfObject = notifications.findIndex(object => {
			return object.id === item.id;
		});
		selectedItems.splice(indexOfObject, 0, item);
		setAllNotifications(notifications)
	}

	const selectAllNotifications = (e) => {
		setSelectedItems([]);
		let temp = [];
		if (e.target.checked) {
			temp = notifications?.map(item => ({
				...item,
				selected: 1,
			}))
			setSelectedItems(temp);
		} else {
			setSelectedItems([]);
			temp = notifications?.map(item => ({
				...item,
				selected: 0,
			}))
		}
		setAllNotifications([...temp]);
	}

	useEffect(() => {
		if (selectedItems?.length === 0 && document.getElementById("checkAll")?.checked) {
			document.getElementById("checkAll").checked = false;
		}
	}, [selectedItems])
	const handleCardClick = (item, cardId) => {
		if (cardId) {
			singleAndMultiSelect(item)
			handleMarkAsRead();
		}
	};


	return (
		<div>
			{notifications.length > 0 && (
				<div className={styles.selectedCountAndCheckboxWrapper}>
					<Typography.Text>
						{`Selected (${selectedItems.length})`}
					</Typography.Text>
					<div className={styles.checkboxWrapper}>
						<input
							type="checkbox"
							id="checkAll"
							onChange={(e) => selectAllNotifications(e)}
							style={{ opacity: 1, pointerEvents: 'fill', position: 'unset' }}
						/>
						<p>Selected All</p>
					</div>
					<Button
						style={{ display: isMobile ? "block" : "none" }}
						disabled={selectedItems.length > 0 ? false : true}
						className={styles.markAsReadBtn}
						onClick={() => handleMarkAsRead()}
						type="primary"
					>
						Mark as read
					</Button>
				</div>
			)}
			<Row className={styles.notificationRow} gutter={[isMobile ? 12 : 24, isMobile ? 12 : 24]}>
				{notifications && notifications?.map((item) => {
					let cardId = ((/^.*card-(\d+)/gm).exec(item.action_link) || [])[1];
					return (
						<Col key={item.id} className={styles.notificationCol} span={isMobile ? 24 : 12}>
							<Card
								bordered={false}
								style={{
									width: '100%',
								}}
								className={`${styles.cardStyle} notificationCards ${item.seen ? "notificationSeenCards" : "notificationUnseenCards"
									}`}
							>
								<div className={styles.cardContentWrapper}>
									<Avatar
										className={styles.avatarStyle}
										onClick={(e) => {
											e.stopPropagation(); // Stop event propagation to parent elements
											singleAndMultiSelect(item);
										}}

									>
										{item.selected ? <img src={SelectedIcon} alt="Icon" /> : null}
										{!item.selected ? (item?.firstname || item?.lastname) ?
											`${item?.firstname.toUpperCase().charAt(0)}${item?.lastname.toUpperCase().charAt(0)}` : "N/A" : null}
									</Avatar>
									<Link style={{ width: "100%" }}
										to={`/card-details/${cardId}`} target="_blank"
										onClick={() => handleCardClick(item, cardId)}
									>
										<div className={styles.divInsideAvatar}>
											<div className={styles.clientNameTime}>
												<Typography.Text className={styles.clientName}>{item.firstname || "N/A"} {item.lastname || "N/A"}</Typography.Text>
												<Typography.Text className={styles.date}>{convertServerToLocalTime(item.created_at)}</Typography.Text>
											</div>
											<Typography.Text className={styles.message}>{item.data}</Typography.Text>
										</div>
									</Link>
								</div>
								{item.notification_unique_id && (
                    <FontAwesomeIcon className={styles.historyIcon} icon={faEye} onClick={() => { setUniqueId(item.notification_unique_id); setIsDrawerOpen(true); }} />
                )}
							</Card>
						</Col>
					);
				})}
			</Row>
			{(notifications.length === 0) && !isLoading && (
				<div className={styles.emptyCardWrapper}>
					<Card className={styles.emptyCard}>
						<Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Card>
				</div>
			)}
			<NotificationDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} uniqueId={uniqueId} />
		</div>
	);
};

export default NotificationListing;
