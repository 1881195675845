import { UNSEEN_INBOX_MESSAGE, SET_PAGE, SET_ROWS_COUNT, SET_CHAT_TITLE, SET_ADD_TITLE, Inbox_LOADING, Inbox_UPDATED_COUNT, SET_INBOX_ADD_MESSAGE, SET_INBOX_MESSAGE, SET_INBOX_CURRENT_SELECTED_MESSAGE, SET_INBOX_HAS_MORE, INBOX_SET_STATE } from "../actions/types";

const initialState = {
    unseenMessages: [],
    page: 1,
    rowsCount: 0,
    chatTitle: "",
    addTitle: "",
    loading: false,
    updatedCount: 0,
    inboxAddMessage: "",
    inboxMessage: [],
    currentSelectedMessage: "",
    inboxHasMore: true,
    inboxState: { comments: {}, commentsArray: [] },
};
export default function (state = initialState, { payload, type }) {
    switch (type) {
        case UNSEEN_INBOX_MESSAGE:
            return { ...state, unseenMessages: payload?.data, unSeenCount: payload?.data?.length };
        case SET_PAGE:
            return { ...state, page: payload };
        case SET_ROWS_COUNT:
            return { ...state, rowsCount: payload };
        case SET_CHAT_TITLE:
            return { ...state, chatTitle: payload };
        case SET_ADD_TITLE:
            return { ...state, addTitle: payload };
        case Inbox_LOADING:
            return { ...state, loading: payload };    
        case Inbox_UPDATED_COUNT:
            return { ...state, updatedCount: payload };   
        case SET_INBOX_ADD_MESSAGE:
            return { ...state, inboxAddMessage: payload }; 
        case SET_INBOX_MESSAGE:
            return { ...state, inboxMessage: payload };   
        case SET_INBOX_CURRENT_SELECTED_MESSAGE:
            return { ...state, inboxCurrentSelectedMessage: payload };
        case SET_INBOX_HAS_MORE:
            return { ...state, inboxHasMore: payload };
        case INBOX_SET_STATE:
            return { ...state, inboxState: payload };            
        default:
            return state;
    }
}
