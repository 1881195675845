import React, { useEffect, useState } from "react";
import { Card, Container, ProgressBar, Table, Tab, Tabs } from "react-materialize";
import { getCardDisplayStatus, isMobile, primaryColor, toISODate } from "../../api/Util";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import StatsTable from "../UserStats";
import CustomerAverages from "../CustomerAverages";
import TopHeader from "../TopHeader";
import { ADMIN, CUSTOMER } from "../../api/Constants";
import styles from './Dashboard.module.scss';
import './DashboardStyle.scss';
import { Spin } from 'antd';
import { getARTData, getDashboardDataBasedOnDate, getDashboardDataForCustomer } from "../../services/DashboardService";
import ARTTable from "./ARTTable";
import CSATTable from "./CSATTable";
import CustomDatePicker from "../common/DatePicker";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function addDays(date, days) {
	let result = new Date(date);
	result.setDate(result.getDate() + days);
	if (new Date(result).getTime() < new Date('2022-12-20').getTime()) {
		result = (new Date('2022-12-20'));
	}
	return result;
}

export default (props) => {
	let loggedInUser = props.auth.loggedInUser;
	const dateDiff = loggedInUser.client_type === CUSTOMER ? -29 : -7;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [ARTData, setARTData] = useState({});
	const [startDate, setStartDate] = useState(addDays(new Date(), dateDiff));
	const [endDate, setEndDate] = useState(new Date());

	const loadData = async () => {
		setLoading(true);
		let paramsObj = {
			start_date: toISODate(startDate),
			end_date: toISODate(endDate),
		};
		let response;
		let artResponse;
		if (loggedInUser.client_type === CUSTOMER) {
			paramsObj = { id: loggedInUser.client_id, ...paramsObj };
			response = await getDashboardDataForCustomer(paramsObj);
		} else {
			response = await getDashboardDataBasedOnDate(paramsObj);
			artResponse = await getARTData(paramsObj);
		}
		if (response.status) {
			setData(response.data);
			setLoading(false);
		}
		if (artResponse?.status) {
			setARTData(artResponse?.data);
		}
	}

	useEffect(() => {
		if (startDate && endDate) {
			loadData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	function showDataInGraph(value, text) {
		return <CircularProgressbar value={value || '0'} text={text || '0'}
			styles={buildStyles({ pathColor: '', textSize: '0.7rem', textColor: '#000000' })} />;
	}

	return <Container>
		{loading && <ProgressBar />}
		<div className="aside-right-top-bar dashboard-top-bar">
			<div className="top-bar-left">
				<div className="top_bar">
					<h3>Dashboard</h3>
				</div>
			</div>
			<div className="top-bar-right">
				<TopHeader />
			</div>
		</div>
		<div className="dashboard-main-page">
			<div className="dashboard-date-picker">
				<Card className={styles.deskMode}>
					<div style={{ display: "flex", gap: "10px" }}>
						<CustomDatePicker className={`${styles.datePicker} ${styles.mobileMode}`} setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
						<div className={styles.dashboardloader}>
							{loading && <Spin />}
						</div>
					</div>
				</Card>
			</div>
			{
				loggedInUser.client_type !== CUSTOMER && !(loggedInUser.client_type === ADMIN) ?
					<Tabs options={{ responsiveThreshold: Infinity }}>
						<Tab title="QA">
							<StatsTable data={{ ...data?.editor_stats }} title="Team Performance" tab={'QaTab'}
								start={startDate} end={endDate} />
							<ARTTable data={{ ...ARTData?.editor_stats }} title="ART" tab={'QaTab'} start={startDate} end={endDate} />
							<CSATTable data={{ ...data?.editor_stats }} title="CSAT" tab={'QaTab'} start={startDate} end={endDate} />
						</Tab>
						<Tab title="Customer">
							<CustomerAverages data={data} />
							<StatsTable data={{ ...data?.customer_data?.customer_stats }} title="Customer Stats" tab={'customerTab'}
								start={startDate} end={endDate} />
							{/* <CSATTable data={{ ...data?.customer_data?.customer_stats }} title="CSAT" tab={'customerTab'} start={startDate} end={endDate} /> */}
						</Tab>
						<Tab title="Own">
							<StatsTable data={{ ...data?.team_lead_data }} title="Team Performance" tab={'TeamLeadTab'}
								start={startDate} end={endDate} />
							<ARTTable data={{ ...ARTData?.team_lead_data }} title="ART" tab={'TeamLeadTab'} start={startDate} end={endDate} />
							<CSATTable data={{ ...data?.team_lead_data }} title="CSAT" tab={'TeamLeadTab'} start={startDate} end={endDate} />
						</Tab>
					</Tabs> : null
			}
			{
				(loggedInUser.client_type === ADMIN) ?
					<Tabs options={{ responsiveThreshold: Infinity }}>
						<Tab title="QA">
							<StatsTable data={{ ...data?.editor_stats }} title="Team Performance" tab={'QaTab'}
								start={startDate} end={endDate} />
							<ARTTable data={{ ...ARTData?.editor_stats }} title="ART" tab={'QaTab'} start={startDate} end={endDate} />
							<CSATTable data={{ ...data?.editor_stats }} title="CSAT" tab={'QaTab'} start={startDate} end={endDate} />
						</Tab>
						<Tab title="Customer">
							<CustomerAverages data={data} />
							<StatsTable data={{ ...data?.customer_data?.customer_stats }} title="Customer Stats" tab={'customerTab'}
								start={startDate} end={endDate} />
							{/* <CSATTable data={{ ...data?.customer_data?.customer_stats }} title="CSAT" tab={'customerTab'} start={startDate} end={endDate} /> */}
						</Tab>
						<Tab title="Team Lead">
							<StatsTable data={{ ...data?.team_lead_data }} title="Team Performance" tab={'TeamLeadTab'}
								start={startDate} end={endDate} />
							<ARTTable data={{ ...ARTData?.team_lead_data }} title="ART" tab={'TeamLeadTab'} start={startDate} end={endDate} />
							<CSATTable data={{ ...data?.team_lead_data }} title="CSAT" tab={'TeamLeadTab'} start={startDate} end={endDate} />
						</Tab>
						<Tab title="YTHelper">
							<StatsTable data={{ ...data?.yt_helper_data }} title="Team Performance" tab={'YTHelperTab'}
								start={toISODate(startDate)} end={toISODate(endDate)} />
							{/* <CSATTable data={{...data?.yt_helper_data }} title="CSAT" tab={'YTHelperTab'} start={startDate} end={endDate} /> */}
						</Tab>
					</Tabs> : null
			}
			{
				loggedInUser.client_type === CUSTOMER ?
					<div style={{ overflowX: 'scroll' }}>
						<Table className="highlight client-dashboard-table custom-responsive-table">
							<thead>
								<tr>
									<th>{isMobile ? "S.No" : "Serial Number"}</th>
									<th>{isMobile ? "Title" : "Card Title"}</th>
									<th>Created Date</th>
									<th>Completed Date</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{
									data && data.length > 0 && data.map((card, i) => {
										return (
											<tr key={`table-card-${card.card_id}`}>
												<td>{i + 1}</td>
												<td className="td-title">{card.card_title}</td>
												<td className="td-creation-time">{toISODate(card.creation_time)}</td>
												<td className="td-done-time">{card.done_time ? toISODate(card.done_time) : '-'}</td>
												<td>{getCardDisplayStatus(card.card_status)}</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</div>
					:
					<div>
						<Card style={{ overflowX: 'scroll' }}>
							<table style={{ minWidth: '840px' }}>
								<thead>
									<tr>
										<th className="center-align" width="16%">Requested Videos</th>
										<th className="center-align" width="16%">In Progress</th>
										<th className="center-align" width="16%">Completed Videos</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="align-center">{showDataInGraph(data?.video_stats?.total_videos > 0 ? 100 : '0', data?.video_stats?.total_videos)}</td>
										<td className="center-align">
											{
												showDataInGraph(Math.ceil((data?.video_stats?.in_progress * 100.0) / data?.video_stats?.total_videos), data?.video_stats?.in_progress)
											}</td>
										<td className="center-align">
											{
												showDataInGraph(((data?.video_stats?.completed / Math.max(data?.video_stats?.total_videos, 1)) * 100), data?.video_stats?.completed)
											} </td>
									</tr>
									<tr>
										<th className="center-align" width="16%">Avg Rating</th>
										<th className="center-align" width="16%">Avg Completion Time</th>
										<th className="center-align" width="16%">Overdue Videos</th>
									</tr>
									<tr>
										<td className="center-align">{
											showDataInGraph(Math.ceil((data?.video_stats?.avg_rating * 100.0) / 5.0),
												data?.video_stats?.avg_rating ? `${data.video_stats.avg_rating.toFixed(1)} (${data.video_stats.rated_videos} ratings)` : '0')
										}</td>
										<td className="center-align">{
											showDataInGraph(data?.video_stats?.avg_time ? 100 : '0', data?.video_stats?.avg_time ? data.video_stats.avg_time.toFixed(2) + " hours" : '0')
										}</td>
										<td className="center-align">{
											showDataInGraph(Math.ceil((data?.video_stats?.overdue * 100.0) / data?.video_stats?.total_videos), data?.video_stats?.overdue)
										}</td>
									</tr>
								</tbody>
							</table>
						</Card>
					</div>
			}
		</div>
	</Container>;
}
