import {axiosClient} from '../api/httpClient';

export const fetchCustomerDetails = async (filterData) => {
	try {
		const data = await axiosClient.get('/api/exportCustomers', {
            params: filterData,
        });
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const getMonthCardRequestOfActiveCustomer = async (filterData, activeCustomerIds) => {
	try {
			const data  = await axiosClient.post(`/api/customers-cards-count${filterData?.subscriptionType ? `?customerType=${filterData.subscriptionType}` : ''}`, 
			activeCustomerIds);
			return {
					status: true,
					data: data?.data
			};
	} catch (error) {
			console.error("Error in fetching month card request:", error);
			return {
					status: false,
					error: "Some Error Occurred"
			};
	}
};

export const fetchCSATData = async (userId, params) => {
	try {
			const data = await axiosClient.get(`/api/user/${userId}/average-rating`, {
					params: params
			});
			return {
					status: true,
					data: data?.data,
			};
	} catch (error) {
			return {
					status: false,
					error: "Some Error Occured",
			};
	}
};

