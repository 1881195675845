import React, { useEffect, useState } from 'react';
import { Button, Card, Layout, Spin, Tabs, Typography } from 'antd';
import { useSelector } from 'react-redux';
import styles from './NotificationStyle.module.scss';
import './style.scss';
import NotificationListing from './NotificationListing/NotificationListing';
import { errorToast, infoToast, isAdmin, isMobile, isTeamLead, successToast } from '../../api/Util';
import TopHeader from '../TopHeader/TopHeader';
import { markAsRead, getNotifications, markAsReadNotifications, deleteOldNotifications } from '../../services/notificationService';
import ReloadIcon from '../../components/img/reload_icon.png';
import { DeleteOutlined } from '@ant-design/icons';

const Notification = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [index, setIndex] = useState(1);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedTab, setSelectedTab] = useState("all");
  const limit = 50;

  const props = useSelector((state) => state)
  const authUser = props.auth?.loggedInUser;
  const authUserId = authUser?.client_id;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const fetchNotifications = async (obj) => {
    setIsLoading(true);
    const response = await getNotifications(obj);
    if (response.status) {
      setNotifications(response.data);
      if (index > 1) {
        setAllNotifications([...allNotifications, ...response.data?.allNotifications]);
      } else {
        if (selectedTab === "all" && index === 1) {
          setAllNotifications(response.data.allNotifications);
          setTodayNotifications(response.data.todayNotifications);
          setYesterdayNotifications(response.data.yesterdayNotifications);
        } else if (selectedTab === "all" && index > 1) {
          setAllNotifications(response.data.allNotifications);
        } else if (selectedTab === "today") {
          setTodayNotifications(response.data.todayNotifications);
        } else if (selectedTab === "yesterday") {
          setYesterdayNotifications(response.data.yesterdayNotifications);
        }
      }
      setIsLoading(false);
      if (response.data.allNotificationsCount) {
          setNotificationsCount(parseInt(response.data.allNotificationsCount));
      }
    } else {
      console.error(response.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
      if (notifications && notifications.length > 0) {
          markAsRead(notifications, authUserId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useEffect(() => {
      if (authUser) {
          fetchNotifications({
            limit: limit,
            page: index,
            group: selectedTab,
          });
      }

      if((isAdmin(authUser) || isTeamLead(authUser) ) && props?.users.qas == null && !props?.users.loadingUsers && props?.fetchAllUsers) {
          props.fetchAllUsers();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, authUser]);

  const onChangeTab = (key) => {
      let temp = [];
      setSelectedTab(key);
      setSelectedItems([]);
      setIndex(1);
      if (selectedTab === "all") {
        temp = mapNotifications(allNotifications, "calledOnTabChange");
        setAllNotifications([...temp]);
      } else if (selectedTab === "today") {
          temp = mapNotifications(todayNotifications, "calledOnTabChange");
          setTodayNotifications([...temp]);
      } else if (selectedTab === "yesterday") {
          temp = mapNotifications(yesterdayNotifications, "calledOnTabChange");
          setYesterdayNotifications([...temp]);
      }
  };

  const handleShowMore = () => {
    setIsLoading(true);
    setIndex(index + 1);
  }

  const reloadNotifications = () => {
    setSelectedItems([]);
    infoToast("Reloading Notifications");
    if (selectedTab === "all") {
      setAllNotifications([]);
    } else if (selectedTab === "today") {
      setTodayNotifications([]);
    } else if (selectedTab === "yesterday") {
      setYesterdayNotifications([]);
    }

    if (index === 1) {
      fetchNotifications({
        limit: limit,
        page: index,
        group: selectedTab,
      });
    }
    setIndex(1);
  }

  const mapNotifications = (notifications, calledFrom) => {
    let temp  = [...notifications];
    selectedItems.map((item) => {
      let index = notifications?.findIndex(element => element.id === item.id);
      if (calledFrom !== "calledOnTabChange") {
        temp[index].seen = 1;
      }
      temp[index].selected = 0;
    })
    return temp;
  }

  const handleMarkAsRead = async () => {
    let temp = [];
    let resultIds = selectedItems
    .filter(notificationsList => notificationsList.seen !== 1) // Added condition
    .map(notificationsList => notificationsList.id);
    if (resultIds.length > 0 ) {
    const response = await markAsReadNotifications(resultIds);
    if (response.status === 200) {
      successToast("Notifications are marked as read successfully");
      if (selectedTab === "all") {
        temp = mapNotifications(allNotifications, "");
        setAllNotifications([...temp]);
      } else if (selectedTab === "today") {
          temp = mapNotifications(todayNotifications, "");
          setTodayNotifications([...temp]);
      } else if (selectedTab === "yesterday") {
          temp = mapNotifications(yesterdayNotifications, "");
          setYesterdayNotifications([...temp]);
      }
      setSelectedItems([]);
    } else {
      console.error(response.error);
    }
  }
  }

  const tabs = [
    { key: 'all', label: 'All', notificationType: 'allNotifications' },
    { key: 'today', label: 'Today', notificationType: 'todayNotifications' },
    { key: 'yesterday', label: 'Yesterday', notificationType: 'yesterdayNotifications' },
  ];
  
  const items = tabs.map(({ key, label, notificationType }) => ({key, label,
    children: <NotificationListing
                notifications={(notificationType === 'allNotifications' ? allNotifications : notificationType === 'todayNotifications' ? todayNotifications : yesterdayNotifications) || []}
                isLoading={isLoading}
                setAllNotifications={notificationType === 'allNotifications' ? setAllNotifications : notificationType === 'todayNotifications' ? setTodayNotifications : setYesterdayNotifications}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                isMobile={isMobile}
                handleMarkAsRead={handleMarkAsRead}
                selectedTab={selectedTab}
              />,
  }));
  
    const deleteNotifications = async () => {
      const response = await deleteOldNotifications();
      if (response.status) {
        successToast(response.message);
      } else {
        errorToast("Some Error Occured");
      }
    }

    return (
        <Layout className={styles.layout}>
          <Card className={styles.topHeader} loading={false}>
            <TopHeader />
          </Card>
          <div className={styles.headingAndButtonWrapper}>
            <Typography.Text className={styles.heading}>
              Notifications
            </Typography.Text>
            <div className={styles.reloadIconAndButtonWrapper}>
              <img src={ReloadIcon} onClick={() => reloadNotifications()} alt="Reload Icon" />
              <Button
                className={styles.markAsReadBtn}
                style={{ display: isMobile ? "none" : "block"}}
                disabled={selectedItems.length > 0 ? false : true}
                onClick={() => handleMarkAsRead()} type="primary">
                Mark as read
              </Button>
              {
                isMobile ? <Button
                    className={styles.deleteNotificationBtn}
                    onClick={() => deleteNotifications()} type="primary">
                      <DeleteOutlined className={styles.deleteIcon} onClick={() => deleteNotifications()} />  3 mon old
                  </Button>
                  : <Button
                      className={styles.deleteNotificationBtn}
                      onClick={() => deleteNotifications()} type="primary">
                        Delete 3 months old
                    </Button>
              }
            </div>
            </div>
          <Tabs defaultActiveKey="all" items={items} onChange={onChangeTab} />
          <div className={styles.loader} style={{ display: notifications.length > 0 ? "none" : "block" }}>
              <Spin size="large" spinning={isLoading} />
          </div>
          {
              notificationsCount > limit && !isLoading && selectedTab === "all" && 
                  <div className={styles.showMoreBtnContainer}>
                      <Button type="primary" onClick={() => handleShowMore()}>Show More</Button>
                  </div>
          }
        </Layout>
    );
}

export default Notification;
