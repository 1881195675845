import { RATING_SET_START_DATE, RATING_SET_END_DATE, RATING_SET_SELECTED_RATING, RATING_SET_INDEX, UPDATED_RATING_CARDS_DATA, TOTAL_RATED_CARDS } from "../actions/types";
const initialState = {
  startDate: new Date(),
  endDate: new Date(),
  selectedRating: undefined,
  index: 1,
  limit: 10,
  cardData: [],
  totalRatedCards: null,
};

export default function (state = initialState, { payload, type }) {
  switch (type) {
    case RATING_SET_START_DATE:
      return { ...state, startDate: payload };
    case RATING_SET_END_DATE:
      return { ...state, endDate: payload };
    case RATING_SET_SELECTED_RATING:
      return { ...state, selectedRating: payload };
    case RATING_SET_INDEX:
      return { ...state, index: payload };
    case UPDATED_RATING_CARDS_DATA:
      return { ...state, cardData: payload }
    case TOTAL_RATED_CARDS:
      return { ...state, totalRatedCards: payload }
    default:
      return state;
  }
};
